import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentPersonCTA({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [currentPerson] = useState(Math.floor(Math.random() * fields.teammembers.length))

  return (
    <section
      data-component="ContentPersonCTA"
      className={clsx(
        fields.background === '#E9EAEA' && 'section section--with-bg bg-site-grey',
        fields.background === '#393C42' && 'section section--with-bg bg-site-base',
        fields.background === '#FFFFFF' && 'section'
      )}
    >
      <div className="container">
        <div
          className={clsx(
            'max-w-[892px] mx-auto bg-site-base rounded-[7px] p-6 grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-8',
            fields.background !== '#393C42' && 'shadow-site-shadow'
          )}
        >
          <div className="flex justify-center">
            <LossePlaatjie
              className="max-sm:max-w-[200px]"
              maxwidth={500}
              alt={`${currentPerson}-person`}
              src={fields.teammembers?.[currentPerson]?.recap?.image}
            />
          </div>
          <div className="sm:col-span-2 flex flex-col justify-center">
            <h2 className="text-white mb-3 sm:mb-6 font-semibold text-2xl sm:text-3xl">{fields.title}</h2>
            <Content className="children-p:text-white">{fields.description}</Content>
            {fields.links && (
              <div className="mt-4 sm:mt-8">
                <Links icons="combo" items={fields.links} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
