import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Teamlid } from '~/graphql/types'
import useGeneralInfo from '~/hooks/useGeneralInfo'
import Button from './Button'

export function PersonCTA({ fields }: { fields: { relation: Teamlid; title: string; buttonStyle?: string } }) {
  const info = useGeneralInfo()

  return (
    <div className="rounded-[7px] min-w-[270px] w-fit shadow-site-shadow bg-site-base px-10 pb-10 pt-4 flex flex-col gap-2">
      <LossePlaatjie maxwidth={185} src={fields.relation.recap.image} />
      <div className="text-white font-semibold text-lg">{fields.title}</div>
      <div className="flex flex-col mt-4 gap-5">
        <Button buttonstyle={fields?.buttonStyle} className="w-full" icons="mail" to={info.mail.url} target={info.mail.target}>
          Mail ons
        </Button>
        <Button buttonstyle="white_outlined" className="w-full" icons="phone" to={info.phone.url} target={info.phone.target}>
          Bel ons
        </Button>
      </div>
    </div>
  )
}
