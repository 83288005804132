import type { Page, Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import Content from '~/components/elements/Content'
import { PersonCTA } from '~/components/elements/PersonCTA'
import { Title } from '~/components/elements/Title'
import { useLoaderData } from '@ubo/losse-sjedel'
import { useState } from 'react'

export default function FormPerson({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const { page } = useLoaderData<{ page: Page }>()
  const [currentPerson] = useState(Math.floor(Math.random() * fields.teammembers.length))

  return (
    <section data-component="FormPerson" className="section section--with-bg bg-site-grey">
      <div className="container">
        <div className="max-w-screen-lg mx-auto">
          <Title className="text-center">{fields.title}</Title>
          <Content className="sm:children-p:text-lg mt-3 sm:mt-6 lg:mt-10 lg:px-20">{fields.description}</Content>
        </div>
        <div className="grid grid-cols-1 max-w-screen-lg mx-auto lg:grid-cols-5 pt-6 sm:mt-8 lg:mt-20 max-lg:gap-10">
          <div className="lg:col-span-2 lg:pt-20 flex flex-col xm:flex-row max-xm:items-center max-lg:justify-center lg:flex-col gap-8 lg:gap-14">
            <div className="flex flex-col gap-3 sm:gap-5">
              <PersonCTA
                fields={{ relation: fields.teammembers[currentPerson], title: fields.teammembertitle, buttonStyle: fields?.buttonstyle }}
              />
            </div>
          </div>
          <div className="lg:col-span-3 relative max-lg:-order-1 p-4 sm:p-8 lg:py-12 lg:px-14 bg-white rounded-[7px] shadow-site-shadow">
            <Form
              data={fields.form}
              renderLoading={() => <Loading />}
              renderStatus={({ content }) => <Content>{content}</Content>}
              privacyUrl="/privacy-policy"
              privacyUrlPlacement="privacy verklaring"
              generate={false}
              scrollToConfirm={false}
              defaultFields={{
                input_14: page.title
              }}
            >
              <div className="flex flex-col gap-6 sm:gap-10">
                <h3 className="font-semibold text-2xl sm:text-3xl">{fields.subtitle}</h3>
                <div className="flex flex-col gap-4">
                  <FormDuufGroup hideIds={[14]} />
                  <FormDuufSubmit />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}
