import clsx from 'clsx'
import type {
  Maybe,
  Page_Flexcontent_Flex_Banner_Links,
  Page_Flexcontent_Flex_Content_Links,
  Page_Flexcontent_Flex_Posts_Links
} from '~/graphql/types'
import Button from './Button'
import type { icons } from '~/types/general'

export default function Links({
  items,
  className,
  theme,
  icons
}: {
  items:
    | Maybe<Page_Flexcontent_Flex_Content_Links>[]
    | Maybe<Page_Flexcontent_Flex_Banner_Links>[]
    | Maybe<Page_Flexcontent_Flex_Posts_Links>[]
  className?: string
  theme?: 'light' | 'dark' | 'accent'
  icons?: icons
}) {
  if (!items || items.length === 0) return null

  const microcopy = items.filter((item) => item?.microcopy)
  const countMicrocopy = microcopy.length

  return (
    <>
      <div className={clsx('flex flex-wrap items-center gap-2 lg:gap-4', className)}>
        {items.map((link, index) => (
          <div key={index} className="max-sm:w-full children-a:w-full">
            {link?.linkMobile && (
              <Button
                index={index}
                icons={icons}
                buttonstyle={link.buttonstyle}
                target={link?.linkMobile.target}
                to={link?.linkMobile.url}
                className="max-sm:hidden"
              >
                {link?.linkMobile.title}
              </Button>
            )}
            <Button
              index={index}
              icons={icons}
              buttonstyle={link.buttonstyle}
              target={link?.link.target}
              to={link?.link.url}
              className={clsx(link?.linkMobile && 'sm:hidden')}
            >
              {link?.link.title}
            </Button>
            {countMicrocopy > 1 && link?.microcopy && (
              <span className={clsx(theme === 'dark' ? 'text-white font-light text-base ml-2 sm:ml-6' : 'font-bold text-xs ml-2 lg:ml-4')}>
                {link.microcopy}
              </span>
            )}
          </div>
        ))}
      </div>
      {countMicrocopy === 1 && (
        <div className={clsx(theme === 'dark' && 'text-white', 'text-sm font-semibold mt-3 max-sm:text-center sm:mt-5 sm:ml-5')}>
          {microcopy.map((item, index) => (
            <div key={index}>{item?.microcopy}</div>
          ))}
        </div>
      )}
    </>
  )
}
