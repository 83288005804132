import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import clsx from 'clsx'
import Links from '~/components/elements/Links'

export default function BannerDefault({ fields, index }: { fields: Page_Flexcontent_Flex_Banner; index: number }) {
  const isFirstElement = index === 0

  return (
    <section data-component="BannerDefault" className="section--pb pt-6 sm:pt-11 section--with-bg bg-site-alt">
      <div className="container h-full">
        {isFirstElement && (
          <div>
            <Breadcrumbs />
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2">
          {fields?.title && (
            <div className="pt-4 sm:pt-7">
              {fields?.title &&
                (isFirstElement ? (
                  <h1 className="flex max-sm:flex-col flex-wrap md:text-center py-2">
                    {fields?.title?.split(' | ').map((part, key) => (
                      <span
                        key={key}
                        className={clsx(
                          'text-3xl xs:text-4xl sm:text-5xl lg:text-6xl uppercase md:text-center font-light mr-1 lg:mr-2 drop-shadow text-white',
                          key !== 0 ? 'font-semibold' : ''
                        )}
                      >
                        {part}
                      </span>
                    ))}
                  </h1>
                ) : (
                  <h2 className="flex max-sm:flex-col flex-wrap md:text-center py-2">
                    {fields?.title?.split(' | ').map((part, key) => (
                      <span
                        key={key}
                        className={clsx(
                          'text-3xl xs:text-4xl sm:text-5xl lg:text-6xl uppercase md:text-center font-light mr-1 lg:mr-2 drop-shadow text-white',
                          key !== 0 ? 'font-semibold' : ''
                        )}
                      >
                        {part}
                      </span>
                    ))}
                  </h2>
                ))}
            </div>
          )}
          {fields?.description && (
            <div className="pt-3">
              <Content className="content children-p:text-white">{fields?.description}</Content>

              {fields?.description && fields?.links && (
                <div className="pt-10 lg:pt-12">
                  <Links items={fields.links} theme="dark" />
                </div>
              )}
            </div>
          )}
        </div>
        {!fields?.description && fields?.links && (
          <div className="pt-10 lg:pt-12">
            <Links items={fields.links} theme="dark" />
          </div>
        )}
      </div>
    </section>
  )
}
